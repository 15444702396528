// STEP 1 - Include Dependencies
// Include react
import React, { useEffect, useState } from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Column2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import bgCircle from "../../../../assets/images/bubbleBgImage.png";

ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

export default function BubbleChart(props) {
    const [bubbleChart, setBubbleChart] = useState([]);

    //dataSource uses for chart property
    const dataSource = {
        chart: {
            xaxisname: "",
            yaxisname: "",
            yAxisValueAlpha: "0",
            xAxisValueAlpha: "0",
            theme: "fusion",
            showvalues: "1",
            plottooltext: "$name",
            valueFontColor: "#FFFFFF",
            valueFontSize: 10,
            labelDisplay: "auto",
            useEllipsesWhenOverflow: "1",
            divLineAlpha: "0",
            bgImage:bgCircle,
            bgAlpha: "1",
            bgImageScale:"75",
            //bgImageScale:"tile",
            bgImageHAlign:"middle",
            bgImageVAlign:"middle",            
            // Set the bubbleScale property to 1.5 to increase the size of the bubbles by 50%
            bubbleScale: "1.6"
        },
        xAxis: {
            visible: "0",
            showLabels:"0"
        },
        categories: [
            {
                category: [
                    {
                        label: "",
                        x: "",
                    }
                ]
            }
        ],
        dataset: [
            {
                is3dOnHover:"1",
                showHoverEffect:"1",
                data: []
            }
        ],
        events: {
            dataPlotClick: function (ev) {
                props.tableHandleChange(ev.data.index, ev.data.displayValue);
            },
            dataplotRollOver: function () {
                // change background color of plottooltext when hovering over a data point
                var plottooltext = document.getElementsByClassName("fusioncharts-div")[0];
                plottooltext.style.backgroundColor = "#000000"; // set background color to black
                plottooltext.style.color = "#fff"; // set text color to white
                plottooltext.style.borderRadius = "6px"; // set text color to white
            },
            dataplotRollOut: function () {
                // reset background color of plottooltext when rolling out of a data point
                var plottooltext = document.getElementsByClassName("fusioncharts-div")[0];
                plottooltext.style.backgroundColor = ""; // reset to default background color
            }
        }
    };

    // Define the algorithm to scale the z values based on the count value
    const scaleZValues = (data, minSize, maxSize) => {
        // Find the minimum and maximum count values in the data
        const countValues = data.map((d) => d.count);
        const minCount = Math.min(...countValues);
        const maxCount = Math.max(...countValues);
    
        // Scale the z values based on the count values
        data.forEach((d) => {
            if(d.count === maxCount){
                const scaledValue = ((d.count - minCount) / (maxCount - minCount)) * (maxSize - minSize) + minSize;
                d.z = scaledValue;
            }else{
                const scaledValue = ((d.count - minCount) / (maxCount - minCount)) * ((maxSize-32) - minSize) + minSize;
                d.z = scaledValue;
            }
            
        });
        return data;
    };

    const makeData = () => {
        // Call the algorithm to scale the z values based on the count value
        const scaledData = scaleZValues(props.bubbleChart, 5, 70);

        // sorting function to sort based on count key
        function sortByCount(a, b) {
            return b.count - a.count;
        }
  
        // sort the array using the sorting function
        const sortedData = scaledData.sort(sortByCount);
        dataSource.dataset[0].data = sortedData;
        setBubbleChart(dataSource);
    };

    useEffect(() => {
        makeData();
    }, [props.refresh]);

    return (
        <ReactFC
            type="bubble"
            width="100%"
            height="100%"
            dataFormat="JSON"
            renderAt="chart-container"
            dataSource={bubbleChart}
            {...bubbleChart}
        />
    );
}