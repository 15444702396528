import React, { useEffect } from "react";
import cloneDeep from "lodash/cloneDeep";
import useState from "react-usestateref";
import Pagination from "react-js-pagination";
import Select from "react-select";
import Sorting from "../../../../assets/images/sorting.png";
import CloseIcon from "../../../../assets/images/close-icon.png";
import DescendingOrder from "../../../../assets/images/descendingOrder.png";

const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
];

// dropdown option css
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: "1px solid gray",
        color: state.isSelected ? "white" : "black",
        backgroundColor: state.isSelected ? "blue" : "",
        borderColor: state.isSelected ? "blue" : "gray", // add border color here
        "&:hover": {
            backgroundColor: state.isSelected ? "blue" : "lightgray"
        }
    })
};

export default function FooterTable(props) {
    const [countPerPage, setCountPerPage, countPerPageRef] = useState(10);
    const [currentPage, setCurrentPage, currentPageRef] = useState(1);
    const [collection, setCollection, collectionRef] = useState([]);
    const [selectedOption, setSelectedOption] = useState({ value: 10, label: "10 per page" });
    const [colorRgba, setColorRgba, colorRgbaRef] = useState("");

    useEffect(() => {
        setCollection(props.tableData.key != undefined ? cloneDeep(props.tableData?.response?.values.slice(0, countPerPageRef.current)) : []);
        updatePage(1);
        let colorRgba = props.tableData?.lejant?.color ? hex2rgba(props.tableData?.lejant?.color, .05) : "";
        setColorRgba(colorRgba);
    }, []);

    useEffect(() => {
        setCountPerPage(10);
        //setCollection(props.tableData.length ? cloneDeep(props.tableData[0]?.tableData.slice(0, countPerPageRef.current)) : []);
        setCollection(props.tableData.key != undefined ? cloneDeep(props.tableData?.response?.values.slice(0, countPerPageRef.current)) : []);
        updatePage(1);
        setSelectedOption({ value: 10, label: "10 per page" });
        let colorRgba = props.tableData?.lejant?.color ? hex2rgba(props.tableData?.lejant?.color, .05) : "";
        setColorRgba(colorRgba);
    }, [props.tableData]);

    const updatePage = (p) => {
        setCurrentPage(p);
        const to = countPerPageRef.current * p;
        const from = to - countPerPageRef.current;
        //setCollection(props.tableData.length ? cloneDeep(props.tableData[0]?.tableData.slice(from, to)) : []);
        setCollection(props.tableData.key != undefined ? cloneDeep(props.tableData?.response?.values.slice(from, to)) : []);
    };
    // this use for update page range
    const updatePageRange = (p, pageSize) => {
        console.log(collection, countPerPage, currentPage, colorRgba); //this need to be include for use react-usestateref package, othewise es lint throw error
        setCurrentPage(p);
        setCountPerPage(pageSize);
        const to = 0;
        const from = pageSize;
        //setCollection(props.tableData.length ? cloneDeep(props.tableData[0]?.tableData.slice(to, from)) : []);
        setCollection(props.tableData.key != undefined ? cloneDeep(props.tableData?.response?.values.slice(to, from)) : []);
    };
    
    // this use for handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        updatePage(pageNumber);
    };
    
    // this use for page size option
    const handlePageSizeOptions = (selectedOption) => {
        let pageNumber = 1;
        let pageSize = selectedOption.value;
        setSelectedOption(selectedOption);
        updatePageRange(pageNumber, pageSize);
    };
   
    // this use for hexa color convert into rgba
    const hex2rgba = (hex, alpha = 1) => {
        const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
        return `rgba(${r},${g},${b},${alpha})`;
    };

    //redirect Url
    const redirectUrl = "https://www.google.com/?q=";

    return (
        <React.Fragment>
            {props.bubbleSelected && props.tableData?.lejant ?
                <div className="data_select">
                    <div><strong>{props.tableData?.lejant?.count}</strong> Result </div>
                    <ul>
                        <li>
                            <a style={{ backgroundColor: "#f3f8f8" }}>
                                <span className="data_marker" style={{ backgroundColor: props.tableData?.lejant?.color }}></span>
                                <span style={{ color: "#000" }}>{props.tableData?.lejant?.value} <img src={CloseIcon} className="" style={{height:"6px",marginLeft:"4px"}} alt="CloseIcon" onClick={()=>props.removeBubbleElement()} /></span>
                            </a>
                        </li>
                    </ul>
                </div> :
                null
            }
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col" className="col-4 firstcol">Count <img src={props.bubbleSelected ? Sorting : DescendingOrder} className="sorting" alt="Sorting" /></th>
                        <th scope="col" className="col-8">Item <img src={props.bubbleSelected ? Sorting : DescendingOrder} className="sorting" alt="Sorting" /></th>
                    </tr>
                </thead>

                <tbody>
                    {props.bubbleSelected && props.tableData?.lejant ?
                        <tr style={{backgroundColor: colorRgbaRef.current}}>
                            <td scope="row" className="col-4 border-end highLightRow firstcol" >{props.tableData?.lejant?.count}</td>
                            <td className="col-8 highLightRow" style={{ color: props.tableData?.lejant?.color}}>{props.tableData?.lejant?.value}</td>
                        </tr> :
                        null
                    }
                    {props.bubbleSelected && collectionRef.current !== undefined && collectionRef.current.length ? collectionRef.current.map((element, i) => {
                        return (
                            <tr key={"table_" + i}>
                                <td scope="row" className="col-4 border-end firstcol">{element?.count}</td>
                                <td className="col-8"><a className="textAnchor" href={redirectUrl+element?.value} target="_blank"
                                    rel="noreferrer">{element?.value} </a></td>
                            </tr>
                        );
                    }) : <td colSpan={2} className="text-center p-5">No data found</td>}
                </tbody>
            </table>
            {props.bubbleSelected && collectionRef.current.length ? 
                <div className="footer_pagination">
                    <div className="itemonpage">
                        <Select
                            value={selectedOption}
                            onChange={handlePageSizeOptions}
                            options={options}
                            styles={customStyles}
                        />
                    </div>
                    <Pagination
                        activePage={currentPageRef.current}
                        itemsCountPerPage={countPerPageRef.current}
                        totalItemsCount={props.tableData?.response?.total ? props.tableData?.response?.total : 0}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-start"
                        activeLinkClass="active"
                        nextPageText=">"
                        prevPageText="<"
                        firstPageText=""
                        lastPageText=""
                    />
                </div>
                : null 
            }

        </React.Fragment>

    );
} 
