import React from "react"; 
import Flag from "../../../../assets/images/flag.png";

export default function App(props) {
    // this function use for convert hexa color to rgba
    const hex2rgba = (hex, alpha = 1) => {
        const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
        return `rgba(${r},${g},${b},${alpha})`;
    }; 
    
    return (
        <div className="sidebar border-start">
            <div className="w-100 p-relative">
                <div className={props.bubbleSelected ? "mb-2 flag noline" : "mb-2 flag"}>
                    <img src={Flag} alt="Flag" className="me-2" />
                    <span>List</span>
                </div>
                {props.sideBarName.length ?
                    <div className="chartdatalist">
                        <ul className="scrollbar">
                            {props.sideBarName.map((element, i) => {
                                let colorRgba = element?.color ? hex2rgba(element?.color, .09) : null;
                                return (
                                    <li key={"side_" + i} onClick={() => props.tableHandle(i,element?.key)}>
                                        <a  className={props.bubbleSelected ? props.selectedIndex == i ? "active" : "unactive" : "active"} style={{ "backgroundColor": props.selectedIndex == i && props.bubbleSelected ? colorRgba : "" }}>
                                            <span>
                                                <span className="marker" style={{ "backgroundColor": element?.color }}></span>
                                                <span className="name">{element?.key}</span>
                                            </span>
                                            <span className="count" style={{ "backgroundColor": "#f3f8f8" }}>{element?.count ? element?.count : 0}</span>
                                        </a>
                                    </li>
                                );
                            }
                            )}
                            
                        </ul>
                    </div>
                    : <div className="nodata">No data found</div> 
                }
            </div>
        </div>
    );
} 
