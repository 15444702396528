import React from "react";
import Select from "react-select";
// import Remove from "../../../../assets/images/remove.png";

const options = [
    { value: "Industries", label: "Industries" },
];  

export default function Dropdown(props) {


    return (
        <div style={{width:"20%"}} className="mesele_dropdown">
            {/* <img src={Remove} alt="Remove" height={"13px"} width={"13px"} className="cross_img" /> */}
            <Select
                value={props.selectedOption}
                onChange={props.handleOptions}
                options={options}
                placeholder="Please select option"
                isClearable
                
            />
        </div>
    );
}