import "./assets/css/style.css";
import Routes from "./routes/Routes";


function App() {
    return (
        <Routes />
    );
}

export default App;