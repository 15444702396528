import React from "react";
import Home   from "../../../../assets/images/logo.png";


export default function App(){
    return(<div>
	    <nav className="top_breadcrumb">        
            <ol className="breadcrumb"> 
                <li className="breadcrumb-item"><a href="#"><img src={Home} alt="Your SVG" /></a></li> 
                {/* <li className="breadcrumb-item"><a href="#">CLIENT</a></li> 
                <li className="breadcrumb-item"><a href="#">MATTER NAME</a></li> 
                <li className="breadcrumb-item"><a href="#">CONTRACT LIST</a></li>  
                <li className="breadcrumb-item active" aria-current="page">ANALYSIS</li>*/}
            </ol>
        </nav>
    </div>);
} 
